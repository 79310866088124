import React, { useContext } from 'react';
import TextInputModal from 'components/Modals/TextInputModal';
import Timer from 'components/Timer/Timer';
import useAnswerControls from 'hooks/useAnswerControls';
import TournamentContext from 'context/TournamentContext';
import TOURNAMENT_STATES from 'constants/tournamentStates';

function EnterAnswer() {
  const {
    showModal, timeLeft, closeModal,
  } = useAnswerControls();

  const { tournament } = useContext(TournamentContext);
  const {
    question,
    tiebreakQuestion,
    stateId,
  } = tournament.handState ?? {};

  const getCurrentQuestion = () => {
    if (stateId === TOURNAMENT_STATES.RIGHT_QUESTION_CHECKING) {
      return question;
    }
    if (stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_ANSWERING) {
      return tiebreakQuestion;
    }

    return null;
  };
  const currentQuestion = getCurrentQuestion();

  return (
    <>
      {showModal
      && (
      <TextInputModal
        allowEmpty
        headerText="Напиши отговор"
        onClose={closeModal}
        minTextLength={1}
        preventClose
        textDescription={currentQuestion}
      >

        <Timer
          initialTime={timeLeft}
          maxTime={timeLeft}
          options={{ top: true }}
          onTimeOut={closeModal}
        />

      </TextInputModal>
      )}
    </>
  );
}

export default EnterAnswer;
