import React, { useState, useEffect, useRef } from 'react';
import TOURNAMENT_STATUS from 'constants/tournamentStatus';
import GlobalTimer from 'components/Timer/GlobalTimer';
import getTimeDiffInMilliseconds from 'utilis/getTimeDiffInMilliseconds';
import { toast } from 'react-toastify';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import useIsMobile from 'hooks/useIsMobile';

const pauseTimes = [
  TOURNAMENT_STATES.QUESTION_ANNOUNCEMENT,
  TOURNAMENT_STATES.TOPIC_ANNOUNCEMENT,
  TOURNAMENT_STATES.TIEBREAK_QUESTION_ANNOUNCEMENT,
  TOURNAMENT_STATES.STANDARD_QUESTION_NUMBER_CHOOSING,
  TOURNAMENT_STATES.TIEBREAK_QUESTION_NUMBER_CHOOSING,
  TOURNAMENT_STATES.RIGHT_QUESTION_CHECKING,
  TOURNAMENT_STATES.TIEBREAK_QUESTION_ANSWERING,
  TOURNAMENT_STATES.RESULTS_ANNOUNCEMENT,
];

function formatTime(remainingTime) {
  const totalSeconds = Math.floor(remainingTime); // Ignore fractional seconds
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

const useGlobalTimer = ({ tournament, isLoading, isFetching }) => {
  const [isTimerSet, setIsTimerSet] = useState(false);

  const toastId = useRef(null);
  const isMobile = useIsMobile();

  const toastStyles = {
    margin: 0,
    padding: 0,
    fontSize: isMobile ? '10px' : '16px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  };

  const toastBodyStyles = {
    margin: 0,
    padding: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
  };

  useEffect(() => {
    if (isLoading || tournament?.tournamentStatusId === TOURNAMENT_STATUS.PENDING) {
      return;
    }

    if (!isTimerSet) {
      setIsTimerSet(true);
      toastId.current = toast(<GlobalTimer endTime={tournament.endTime} />, {
        position: 'bottom-left',
        autoClose: getTimeDiffInMilliseconds(tournament.endTime, 'Europe/Sofia'),
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
        closeButton: false,
        pauseOnFocusLoss: false,
        style: isMobile ? toastStyles : { maxWidth: '250px' },
        bodyStyle: isMobile ? toastBodyStyles : null,
      });
    }
  }, [isLoading, tournament?.tournamentStatusId, isFetching]);

  useEffect(() => {
    toast.update(toastId.current, {
      style: isMobile ? toastStyles : { maxWidth: '250px' },
      bodyStyle: isMobile ? toastBodyStyles : null,
    });
  }, [isMobile]);

  useEffect(() => {
    if (!toastId.current || !tournament?.endTime) {
      return;
    }

    if (pauseTimes.includes(tournament?.handState?.stateId)) {
      toast.update(toastId.current, {
        render: `
        ⏸
        ${formatTime(tournament.remainingSeconds)}
        минути`,
        autoClose: false,
      });
    } else {
      toast.update(toastId.current, {
        render: <GlobalTimer endTime={tournament?.endTime} />,
        autoClose: getTimeDiffInMilliseconds(tournament?.endTime, 'Europe/Sofia'),
      });
    }
  }, [
    tournament?.handState?.stateId,
  ]);

  useEffect(() => () => {
    // if user navigates back, removes the timer
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
  }, []);
};

export default useGlobalTimer;
